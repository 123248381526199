import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppLayoutActions } from '../../common/store/app-layout.actions';
import * as SpecificationStructureActions from './actions';
import { SpecificationService } from '../services/specification.service';

@Injectable()
export class SpecificationStructureEffects {
  loadSpecificationStructure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SpecificationStructureActions.loadFamilies),
      switchMap(() => {
        return this.specificationService.getFamilies().pipe(
          map((families) => {
            return SpecificationStructureActions.loadFamiliesSuccess({ families: families.families });
          }),
          catchError((error) => of(AppLayoutActions.showErrorToast({ title: 'Error', message: error.message }))),
          tap(() => { this.store.dispatch(AppLayoutActions.loader({ isLoading: false })); })
        );
      })
    )
  );


  constructor(
    private actions$: Actions,
    private store: Store, 
    private specificationService: SpecificationService
  ) { }
}

